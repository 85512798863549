import React from "react";
import PropTypes from "prop-types";
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Switch as MuiSwitch } from "@material-ui/core";

const Switch = ({ legend, label, color = "primary", checked = false, onChange, error, helperText, size = "medium" }) => {
    return (
        <FormControl error={Boolean(error)}>
            {legend ? <FormLabel component="legend">{legend}</FormLabel> : null}
            <FormControlLabel
                label={label}
                control={
                    <MuiSwitch size={size} checked={Boolean(checked)} onChange={onChange} color={color} />
                }
                onChange={onChange} />
            {error || helperText ? <FormHelperText>{error || ""}{error && helperText ? " - " : ""}{helperText || ""}</FormHelperText> : null}
        </FormControl>
    );
};

Switch.propTypes = {
    label: PropTypes.string.isRequired
};

export default React.memo(Switch);
