const { round } = require("./utils");

function calculateServiceFee({ mid, total, seats, isACH = false }){
    total = Number(total) || 0;
    mid = mid || {};
    const serviceGroupID = Number(mid.serviceGroupID) || 0;
    const fee = Number(mid.fee) || 0;
    const fee2 = Number(mid.fee2) || 0;
    const fee1Limit = Number(mid.fee1Limit) || 0;
    const feePercent = Number(mid.feePercent) || 0;
    const doesNotHaveSeatFees = [1734, 1876].includes(serviceGroupID);
    const seatFee = Number(mid.seatFee) || 0.05; // DEFAULT SEAT FEE IS 5%
	
    let rtn = 0.0;
    let seatFees = 0;

    if (seats && seats.length && !doesNotHaveSeatFees){
        const seatPrice = seats.reduce((sum, seat) => {
            sum = sum + Number(seat.price) * Number(seat.quantity);
            return sum;
        }, 0);
        seatFees = seatPrice * seatFee; // ADDING CHARGE FOR ALL RESERVED SEATS
    }
	
    if (isACH && mid.achEnabled) {
        const achFee = Number(mid.achFee) || 0;
        const achFeePercent = Number(mid.achFeePercent) || 0;
        if (achFee > 0) return round(achFee + seatFees);
        if (achFeePercent > 0) return round(achFeePercent * total + seatFees);
        return round(rtn + seatFees);
    }

    if (fee2 > 0 && fee1Limit > 0 && total > fee1Limit) {
        return round(fee2 + seatFees);
    }

    if (fee > 0 && feePercent > 0) {
        return round(fee + feePercent * total + seatFees);
    }

    if (fee > 0 ) {
        return round(fee + seatFees);
    }

    if (feePercent > 0) {
        if (mid.useTrueCostCalculation){
            const totalFees = total / (1 - feePercent) - total;
            return round(totalFees + seatFees); // this calculates the exact fee on itself: 100.00 @ 3.5% === 103.63
        }
        return round(feePercent * total + seatFees);
    }

    return round(rtn + seatFees);
}

module.exports = calculateServiceFee;
