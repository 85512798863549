import {
    AccountBalance,
    AccountBox,
    BarChart,
    CloudQueue,
    ContactSupportOutlined,
    CreditCard,
    Dashboard,
    Description,
    Event,
    Group,
    LabelImportant,
    LocalHospital,
    Mail,
    Map,
    MonetizationOn,
    Settings,
    ShoppingCart,
    Tune
} from "@material-ui/icons";

import {
    ADDRESS,
    ADMIN,
    AOB,
    AOB_LOOKUP,
    AOB_PUBLIC,
    ATTENDEE,
    BILL_LOOKUP,
    BILL_PAYMENT,
    BILL_PAYMENT_BATCH,
    BILLING,
    BILLING_SCHED,
    CALL_LOG,
    CAMPAIGNS,
    CART,
    CONFIG,
    CONTACT,
    CUSTOM_FIELD,
    DASHBOARD,
    DOCUMENTS,
    DONATION,
    EVENT,
    EVENT_OFFER,
    EVENT_PUBLIC,
    EVENT_SETTING,
    EVENT_TYPE,
    EXPRESS_PAY,
    HELP,
    INVOICE,
    INVOICE_ITEM,
    INVOICE_PAYMENT,
    MEMBER,
    MEMBER_DEPENDENT,
    MEMBER_DISCOUNT,
    MEMBER_GROUP,
    MEMBER_GROUP_SETTING,
    MEMBER_INSURANCE,
    MEMBER_NOTE,
    MEMBER_PUBLIC,
    MEMBERS_DONATION_SCHED,
    MEMBERS_MEMBERSHIP_SCHED,
    MEMBERSHIP,
    MEMBERSHIP_TYPE,
    MESSAGES,
    MID,
    OFFICE,
    PAYMENT_ADD,
    PAYMENT_SCHED,
    POS_CATEGORIES,
    POS_PRODUCTS,
    POS_SIZES,
    POS_VARIATION,
    POSTING,
    REPORTS,
    SEATS,
    STORE,
    TICKET,
    TICKET_COMMENT,
    USER,
    VIRTUAL_TERMINAL
} from "./consts";

export const iconComponents = {
    [ADMIN]: Settings,
    [ADDRESS]: Map,
    [AOB]: LocalHospital,
    [BILLING]: MonetizationOn,
    [CALL_LOG]: Description,
    [CAMPAIGNS]: Mail,
    [CONFIG]: Tune,
    [DASHBOARD]: Dashboard,
    [DOCUMENTS]: CloudQueue,
    [EVENT]: Event,
    [HELP]: ContactSupportOutlined,
    [MEMBER]: AccountBox,
    [ATTENDEE]: Group,
    [STORE]: ShoppingCart,
    [POSTING]: AccountBalance,
    [REPORTS]: BarChart,
    [VIRTUAL_TERMINAL]: CreditCard
};

export const getModuleIcon = module => {
    if (iconComponents[module]) {
        return iconComponents[module];
    }
    return LabelImportant;
};

export const sysCodeLinks = [
    { sysCode: "AOB", title: "AOB Lookup Page", link: "/assignBenefits/:agencyNameKey/" },
    { sysCode: "BLL", title: "Bill Lookup Page", link: "/paymybill/:agencyNameKey/" },
    { sysCode: "TML", title: "Express Pay Page", targType: "TERMINAL", link: "/expresspay/:expressKey/" },
    { sysCode: "EVT", title: "Events Page", targType: "EVENT", link: "/p/:agencyNameKey/events" },
    { sysCode: "MEM", title: "Membership Page", targType: "MEMBER", link: "/p/:agencyNameKey/membership" },
    { sysCode: "MEM", title: "Membership Group Pay Page", targType: "MEMBER", link: "/p/:agencyNameKey/groupPay" },
    { sysCode: "MEM", title: "Member Check-In", targType: "MEMBER", link: "/p/:agencyNameKey/member/checkin" },
    { sysCode: "DON", title: "Donations Page", targType: "DONATE", link: "/p/:agencyNameKey/donate" },
    { sysCode: "EPS", title: "Online Store Page", targType: "EVENT", link: "/p/:agencyNameKey/store" }
];

/* navItem.module is used to associate icons, setup above */
export const navItems = [
    { module: DASHBOARD, label: "Dashboard", path: "/" },
    { module: MEMBER, label: "Profiles", path: "/member", auth: "DMM" },
    { module: BILLING, label: "Billings", path: "/billing", auth: "BLL" },
    { module: POSTING, label: "Posting", path: "/posting", auth: "PST" },
    { module: VIRTUAL_TERMINAL, label: "Virtual Terminal", path: roles => roles?.MEM?.INVOICE && !roles?.TML ? "/vterminal/customer" : "/vterminal", auth: "TML" },
    { module: CALL_LOG, label: "Call Log", path: "/callLog", auth: "CLG" },
    { module: AOB, label: "AOB", path: "/aob", auth: "AOB" },
    { module: CAMPAIGNS, label: "Campaigns", path: "/campaigns", auth: "CMP" },
    { module: ADDRESS, label: "Addresses", path: "/address", auth: "ADD" },
    { module: EVENT, label: "Event Manager", path: "/eventManager", auth: "EVT" },
    { module: STORE, label: "Store Manager", path: "/storeManager", auth: "POS" },
    { module: REPORTS, label: "Reports", path: "/reports", auth: "RPT" },
    { module: DOCUMENTS, label: "Documents", path: "/documents", auth: "DOC" },
    { module: ADMIN, label: "Admin", path: "/admin", auth: "ADM" },
    { module: HELP, label: "Support", path: "/support"
        // Example module actions
        // actions: [
        //     { text: "log this", callback: () => console.log("test") },
        //     { text: "Add new", callback: history => history.push("/config/add") }
        // ]
    }
];

export const sysAdminNavItems = [{ module: CONFIG, label: "System Config", path: "/config", auth: "" }];

export const moduleRoutes = {
    [AOB]: {
        GET: "/api/aob",
        LIST: "/api/aob/list",
        FORM: "/api/aob/form",
        SAVE: "/api/aob/save",
        SEND: "/api/aob/send"
    },
    [AOB_LOOKUP]: {
        FORM: "/api/public/aob",
        LOOKUP: "/api/public/aob/lookup",
        VERIFY: "/api/public/aob/verify"
    },
    [AOB_PUBLIC]: {
        FORM: "/api/public/aob/form",
        SAVE: "/api/public/aob/save"
    },
    [ATTENDEE]: {
        GET: "/api/event/attendee",
        LIST: "/api/event/attendee/list",
        FORM: "/api/event/attendee/form",
        SAVE: "/api/event/attendee/save",
        DELETE: "/api/event/attendee/delete"
    },
    [BILL_LOOKUP]: {
        FORM: "/api/public/bill/form",
        LOOKUP: "/api/public/bill/lookup"
    },
    [BILL_PAYMENT]: {
        FORM: "/api/public/bill/pay/form",
        SAVE: "/api/public/bill/pay/save"
    },
    [BILL_PAYMENT_BATCH]: {
        FORM: "/api/billing/batch/form",
        UPDATE: "/api/billing/batch/update",
        SAVE: "/api/billing/batch/save",
        RESET: "/api/billing/batch/reset"
    },
    [BILLING]: {
        GET: "/api/billing",
        LIST: "/api/billing/list",
        FORM: "/api/billing/form",
        SAVE: "/api/billing/save",
        DELETE: "/api/billing/delete",
        EXISTS: "/api/billing/validBillNumber",
        PRINT: "/api/billing/print",
        REFUND_FORM: "/api/billing/refundForm",
        REFUND: "/api/billing/refund"
    },
    [BILLING_SCHED]: {
        GET: "/api/billing/schedule",
        LIST: "/api/billing/schedule/list",
        FORM: "/api/billing/schedule/form",
        SAVE: "/api/billing/schedule/save",
        DELETE: "/api/billing/schedule/delete"
    },
    [CART]: {
        GET: "/api/public/cart/get",
        FORM: "/api/public/cart/form",
        UPDATE: "/api/public/cart/update",
        SAVE: "/api/public/cart/checkout",
        SUMMARY: "/api/public/cart/summary"
    },
    [CONFIG]: {
        GET: "/api/servicegroup",
        FORM: "/api/servicegroup/form",
        SAVE: "/api/servicegroup/save",
        ADMIN: "/api/servicegroup/admin"
    },
    [CONTACT]: {
        GET: "/api/contact",
        LIST: "/api/contact/list",
        FORM: "/api/contact/form",
        SAVE: "/api/contact/save",
        DELETE: "/api/contact/delete"
    },
    [CUSTOM_FIELD]: {
        GET: "/api/customField",
        LIST: "/api/customField/list",
        FORM: "/api/customField/form",
        SAVE: "/api/customField/save",
        DELETE: "/api/customField/delete",
        REORDER: "/api/customField/reorder"
    },
    [DOCUMENTS]: {
        LIST: "/api/document/list",
        UPLOAD: "/api/document/upload",
        FORM: "/api/document/form",
        SAVE: "/api/document/save",
        DELETE: "/api/document/delete"
    },
    [DONATION]: {
        GET: "/api/member/donation",
        FORM: "/api/member/donation/form",
        SAVE: "/api/member/donation/save",
        RECEIPT: "/api/member/donation/receipt"
    },
    [EXPRESS_PAY]: {
        FORM: "/api/public/expresspay/form",
        SAVE: "/api/public/expresspay/save"
    },
    [EVENT]: {
        GET: "/api/event",
        LIST: "/api/event/list",
        FORM: "/api/event/form",
        SAVE: "/api/event/save",
        DELETE: "/api/event/delete",
        HOLD_FORM: "/api/event/seats/hold/form",
        HOLD_SAVE: "/api/event/seats/hold/save",
        SEAT_FORM: "/api/event/seats/add/form",
        SEAT_SAVE: "/api/event/seats/add/save"
    },
    [EVENT_OFFER]: {
        GET: "/api/event/offer",
        LIST: "/api/event/offer/list",
        FORM: "/api/event/offer/form",
        SAVE: "/api/event/offer/save",
        DELETE: "/api/event/offer/delete"
    },
    [EVENT_PUBLIC]: {
        DETAIL: "/api/public/event",
        LIST: "/api/public/event/list",
        TICKET: "/api/public/event/ticket",
        CHECKIN: "/api/public/event/ticket/checkin"
    },
    [EVENT_SETTING]: {
        GET: "/api/event/setting",
        FORM: "/api/event/setting/form",
        SAVE: "/api/event/setting/save",
        DELETE: "/api/event/setting/delete"
    },
    [EVENT_TYPE]: {
        GET: "/api/event/type",
        LIST: "/api/event/type/list",
        FORM: "/api/event/type/form",
        SAVE: "/api/event/type/save",
        DELETE: "/api/event/type/delete",
        REORDER: "/api/event/type/reorder"
    },
    [INVOICE]: {
        GET: "/api/invoice",
        LIST: "/api/invoice/list",
        FORM: "/api/invoice/form",
        SAVE: "/api/invoice/save",
        DELETE: "/api/invoice/delete"
    },
    [INVOICE_ITEM]: {
        FORM: "/api/invoice/item/form",
        SAVE: "/api/invoice/item/save",
        DELETE: "/api/invoice/item/delete"
    },
    [INVOICE_PAYMENT]: {
        FORM: "/api/invoice/payment/form",
        SAVE: "/api/invoice/payment/save",
        DELETE: "/api/invoice/payment/delete",
        REFUND: "/api/invoice/payment/refund"
    },
    [MEMBER]: {
        GET: "/api/member",
        LIST: "/api/member/list",
        FORM: "/api/member/form",
        SAVE: "/api/member/save",
        DELETE: "/api/member/delete"
    },
    [MEMBER_DISCOUNT]: {
        FORM: "/api/member/discount/form",
        SAVE: "/api/member/discount/save"
    },
    [MEMBERSHIP]: {
        GET: "/api/member/membership",
        FORM: "/api/member/membership/form",
        SAVE: "/api/member/membership/save"
    },
    [MEMBERSHIP_TYPE]: {
        GET: "/api/member/membershipType",
        LIST: "/api/member/membershipType/list",
        FORM: "/api/member/membershipType/form",
        DELETE: "/api/member/membershipType/delete"
    },
    [MEMBER_DEPENDENT]: {
        GET: "/api/member/dependent",
        FORM: "/api/member/dependent/form",
        SAVE: "/api/member/dependent/save",
        DELETE: "/api/member/dependent/delete"
    },
    [MEMBER_GROUP]: {
        GET: "/api/member/group",
        LIST: "/api/member/group/list",
        FORM: "/api/member/group/form",
        SAVE: "/api/member/group/save",
        DELETE: "/api/member/group/delete"
    },
    [MEMBER_GROUP_SETTING]: {
        FORM: "/api/member/group/setting/form",
        SAVE: "/api/member/group/setting/save",
        DELETE: "/api/member/group/setting/delete",
        FORM_CARD: "/api/member/group/setting/formCard",
        SAVE_CARD: "/api/member/group/setting/saveCard",
        FORM_CHECK: "/api/member/group/setting/formCheck",
        SAVE_CHECK: "/api/member/group/setting/saveCheck"
    },
    [MEMBER_INSURANCE]: {
        GET: "/api/member/insurance",
        FORM: "/api/member/insurance/form",
        SAVE: "/api/member/insurance/save",
        DELETE: "/api/member/insurance/delete"
    },
    [MEMBER_PUBLIC]: {
        GET: "/api/public/member",
        FORM: "/api/public/member/form",
        LOGIN: "/api/public/member/login",
        LOOKUP: "/api/public/member/lookup",
        REG_FORM: "/api/public/member/register/form",
        REG_SAVE: "/api/public/member/register/save",
        JOIN_FORM: "/api/public/member/join/form",
        JOIN_SAVE: "/api/public/member/join/save",
        MEM_DATE: "/api/public/member/membership/dates",
        PROFILE_FORM: "/api/public/member/profile/form",
        PROFILE_SAVE: "/api/public/member/profile/save",
        INS_FORM: "/api/public/member/insurance/form",
        INS_SAVE: "/api/public/member/insurance/save",
        SPOUSE_FORM: "/api/public/member/spouse/form",
        SPOUSE_SAVE: "/api/public/member/spouse/save",
        DEP_FORM: "/api/public/member/dependent/form",
        DEP_SAVE: "/api/public/member/dependent/save"
    },
    [MEMBER_NOTE]: {
        GET: "/api/member/note",
        FORM: "/api/member/note/form",
        SAVE: "/api/member/note/save",
        DELETE: "/api/member/note/delete",
        COMPLETE: "/api/member/note/complete"
    },
    [MEMBERS_DONATION_SCHED]: {
        GET: "/api/member/donation/schedule",
        FORM: "/api/member/donation/schedule/form",
        SAVE: "/api/member/donation/schedule/save",
        DELETE: "/api/member/donation/schedule/delete"
    },
    [MEMBERS_MEMBERSHIP_SCHED]: {
        GET: "/api/member/membership/schedule",
        FORM: "/api/member/membership/schedule/form",
        SAVE: "/api/member/membership/schedule/save",
        DELETE: "/api/member/membership/schedule/delete"
    },
    [MID]: {
        GET: "/api/serviceGroup/mid",
        FORM: "/api/serviceGroup/mid/form",
        SAVE: "/api/serviceGroup/mid/save"
    },
    [MESSAGES]: {
        GET: "/api/message",
        LIST: "/api/message/list",
        FORM: "/api/message/form",
        SAVE: "/api/message/save",
        DELETE: "/api/message/delete"
    },
    [OFFICE]: {
        GET: "/api/office",
        LIST: "/api/office/list",
        FORM: "/api/office/form",
        SAVE: "/api/office/save",
        DELETE: "/api/office/delete"
    },
    [PAYMENT_ADD]: {
        GET: "/api/billing/payment",
        FORM: "/api/billing/payment/form",
        SAVE: "/api/billing/payment/save",
        REFUND: "/api/billing/payment/refund"
    },
    [PAYMENT_SCHED]: {
        FORM: "/api/billing/form",
        SAVE: "/api/billing/save"
    },
    [POS_PRODUCTS]: {
        GET: "/api/pos/product",
        FORM: "/api/pos/product/form",
        SAVE: "/api/pos/product/save",
        LIST: "/api/pos/product/list",
        DELETE: "/api/pos/product/delete"
    },
    [POS_CATEGORIES]: {
        GET: "/api/pos/category",
        LIST: "/api/pos/category/list",
        FORM: "/api/pos/category/form",
        SAVE: "/api/pos/category/save",
        DELETE: "/api/pos/category/delete"
    },
    [POS_SIZES]: {
        GET: "/api/pos/size",
        LIST: "/api/pos/size/list",
        FORM: "/api/pos/size/form",
        SAVE: "/api/pos/size/save",
        DELETE: "/api/pos/size/delete"
    },
    [POS_VARIATION]: {
        GET: "/api/pos/variation",
        LIST: "/api/pos/variation/list",
        FORM: "/api/pos/variation/form",
        SAVE: "/api/pos/variation/save",
        DELETE: "/api/pos/variation/delete"
    },
    [POSTING]: {
        BATCH: "/api/posting/batch",
        DELETE: "/api/posting/delete",
        FORM: "/api/posting/form",
        ITEMS: "/api/posting/items",
        POST: "/api/posting/post",
        RESET: "/api/posting/reset",
        SAVE: "/api/posting/save",
        STAGE: "/api/posting/stage",
        UPDATE: "/api/posting/update"
    },
    [SEATS]: {
        // STATUS: "/api/public/event/seats/status"
        // FORM: "/api/public/event/seats/form"
    },
    [TICKET]: {
        GET: "/api/ticket",
        LIST: "/api/ticket/list",
        FORM: "/api/ticket/form",
        SAVE: "/api/ticket/save",
        DELETE: "/api/ticket/delete"
    },
    [TICKET_COMMENT]: {
        GET: "/api/ticket",
        LIST: "/api/ticket/comment/list",
        FORM: "/api/ticket/comment/form",
        SAVE: "/api/ticket/comment/save",
        DELETE: "/api/ticket/comment/delete"
    },
    [USER]: {
        GET: "/api/user",
        LIST: "/api/user/list",
        FORM: "/api/user/form",
        SAVE: "/api/user/save",
        DELETE: "/api/user/delete"
    },
    [VIRTUAL_TERMINAL]: {
        LIST: "/api/terminal/list",
        FORM: "/api/terminal/form",
        CAPTURE: "/api/terminal/capture",
        REFUND: "/api/terminal/refund",
        INQUIRE: "/api/terminal/inquire"
    }
};
