import store from "../store";
import { addValidationError, removeValidationError } from "../actions/formActions";
import { isValidDate } from "./utils";

export const validators = {
    email: val => !val || (val && /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(val)),
    card: val => String(val).trim().length >= 15,
    exp: val => {
        if (val && String(val).trim().length === 5){
            let [mm, yy] = val.split("/");
            mm = Number(mm);
            yy = Number(yy);
            if (mm > 12 || mm < 1) return false;
            const card = new Date(yy + 2000, mm, 0);
            const today = new Date();
            const lastDateOfThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
            return lastDateOfThisMonth <= card;
        }
        return false;
    },
    date: val => !val || (val && isValidDate(val)),
    cvv: val => val && String(val).trim().length >= 3,
    amount: val => Number(val) > 0.00
};

export const validations = [
    { fld: "email", msg: "Invalid email address", func: validators.email },
    { fld: "cardNumber", msg: "Invalid credit card", func: validators.card },
    { fld: "exp", msg: "Invalid expiration", func: validators.exp },
    { fld: "cvv", msg: "Invalid security code", func: validators.cvv },
    { fld: "amount", msg: "Required", func: validators.amount }
];

/**
 * validateForm
 * @param reqFields array of String field names
 * @param reqFields array of custom validations
 */
const validateForm = (reqFields, validations = [], validateCustomFields = true) => {
    const { dispatch, getState } = store;

    const logged = {};
    const { data, errors, custom } = getState().form;
    if (validateCustomFields && custom && Array.isArray(custom) && custom.length > 0) {
        const customFlds = custom.filter(f => f.required).map(f => `custom_field_${f.fieldID}`);
        if (customFlds.length > 0) {
            reqFields = [...reqFields, ...customFlds];
        }
    }
	
    const fldsNoLongerRequired = Object.keys(errors).filter(key => !reqFields.includes(key));
    for (const fld of fldsNoLongerRequired){
        dispatch(removeValidationError(fld));
    }
	
    for (const fld of reqFields) {
        if (typeof data[fld] === "undefined" || data[fld] === null || data[fld] === false || data[fld].length === 0 || (typeof data[fld] === "object" && Object.keys(data[fld]).length === 0)) {
            logged[fld] = true;
            dispatch(addValidationError(fld, "Required"));
        }
        else {
            dispatch(removeValidationError(fld));
        }
    }
	
    for (const validation of validations) {
        const { fld, msg, func, verboseFunc } = validation;
        if (!logged[fld] && fld && msg && typeof func === "function"){
            if (!func(data[fld], data)) {
                dispatch(addValidationError(fld, msg));
            }
            else {
                dispatch(removeValidationError(fld));
            }
        }
        else if (fld && verboseFunc && typeof verboseFunc === "function"){
            const { valid, msg } = verboseFunc(data[fld], data);
            if (!valid) {
                dispatch(addValidationError(fld, msg));
            }
            else {
                dispatch(removeValidationError(fld));
            }
        }
    }
	
    if (Object.keys(getState().form.errors).length > 0) {
        return false;
    }

    return true;
};

export default validateForm;
